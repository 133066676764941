import throttle from "lodash/throttle"

export default function RubberBandPinchColor(opts = {}) {
  const body = document.querySelector("body")
  const bodyDefaultColor = "#fff"
  const html = document.documentElement
  const windowHeight = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  )

  // elements that we want to "steal" the background color from for the
  // rubberband area
  const classesToLookUpColor = [
    ".story-header-container",
    ".connection-header-container",
    ".connect-header-container",
    ".header-container",
    ".connection-header",
    ".service-header",
    ".site-header",
    ".unified-header", // the most common el should always come last
  ]

  // elements that we want to "steal" the background color from for the
  // rubberband area in the footer
  const classesToLookUpColorFooter = [
    ".site-footer", // the most common el should always come last
  ]

  const rbColor = opts.color || detectColor(classesToLookUpColor)
  const rbColorFooter = detectColor(classesToLookUpColorFooter) || rbColor

  if (rbColor) {
    if (opts.updateBottomBg) {
      const onScroll = throttle(() => {
        opts.platform
          ? setBgWhiteAfterHalfHeight(body, rbColor, windowHeight)
          : setBgColorAfterHalfHeight(body, rbColor, rbColorFooter, windowHeight)
      }, 30)

      window.addEventListener("scroll", onScroll, { passive: true })
      window.addEventListener("pagehide", function () {
        window.removeEventListener("scroll", onScroll, { passive: true })
      })
    }

    setTimeout(() => {
      body.style.backgroundColor = rbColor
    })
  }
}

function detectColor(queries) {
  let sourceEl, elStyle

  queries.find(query => {
    return (sourceEl = document.querySelector(query))
  })

  if (!sourceEl) return null

  // use computed style because we want to make sure to grab the real color
  // being rendered, not what's in the CSS declaration
  elStyle = window.getComputedStyle(sourceEl, null)
  return elStyle.backgroundColor
}

function setBgWhiteAfterHalfHeight(el, topColor, windowHeight) {
  window.scrollY >= windowHeight / 2.5 ? (el.style.backgroundColor = "#fff") : (el.style.backgroundColor = topColor)
}

function setBgColorAfterHalfHeight(el, topColor, footerColor, windowHeight) {
  window.scrollY >= windowHeight / 1.5
    ? (el.style.backgroundColor = footerColor)
    : (el.style.backgroundColor = topColor)
}
